import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`(Rest Day)`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today we will have our annual free to all 4th of July workout at
9:00am.  This is a partner wod so bring a friend or come alone and we’ll
pair you up!  Open gym will follow from 10am-1pm but the 9:00am class is
the only class for the day.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`4th of July Partner WOD`}</em></strong></p>
    <p>{`200FT Bear Crawl (as a team)\\
74 Burpees (partner holds a plank while the other burpees)\\
74 Wall Balls (20/14`}{`#`}{`, partner hollow rocks while the other throws wall
balls)\\
74 KBS’s (53/35`}{`#`}{`, partner holds a back extension while the other swings
the kettle bell)\\
74 Push Ups (partner planks in push up position while the other does
pushups)\\
74 Sit Ups (partner does “dying cockroach” while the other does
sit-ups)\\
74 Squats (partner does a squat hold at parallel while the other
squats)\\
74 Pull Ups (partner holds themselves up at the top of a ring dip while
the other does pull-ups)\\
740ft Sled Pull (135`}{`#`}{` total on sled, as a team)`}</p>
    <p><em parentName="p">{`*`}{`compare to 7/4/2016 & 7/4/2015`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      